import React from 'react'

const Dashboard = () => {
  return (
    <div className='w-full h-full bg-red-900'>
        Dashboard
        ksdjkasjd Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab iusto fugit perferendis quia velit natus possimus excepturi commodi dicta, sunt eveniet asperiores tempora dolor deserunt quaerat quibusdam vero totam tenetur necessitatibus nisi? Praesentium obcaecati voluptates sit? Atque corrupti eum omnis itaque placeat eaque id.
    </div>
  )
}

export default Dashboard
