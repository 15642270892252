import React, { Fragment } from 'react'
// import { useLocation } from 'react-router-dom'



function MyCards() {

    // let cardData = useLocation().state.cardArray


  return (
    <Fragment>
      <section className='h-full bg-white w-full'>
        <div className='w-[80%] mx-auto'>
            {/* {
                cardData.map((elm,ind)=>)
            } */}

        </div>
      </section>
    </Fragment>
  )
}

export default MyCards
